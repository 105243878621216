import Document from "../components/v2/Document";
import faqData from "../components/_pages/faqs/faqs.js";
import FAQs from "../components/_programmes/FAQs";
import FullWidthSection from "../components/section/FullWidthSection";
import Title from "../components/Title";
import useAnonymousDataLayer from "../../../src/_hooks/useAnonymousDataLayer";
import heroVideoposter from "@images/homepage/preview.jpg";

const FAQsDocument = () => {
	const { event } = useAnonymousDataLayer();

	const handleEvent = (action) => {
		event(`${action} FAQ item`, "FAQ", "FAQ Interactions");
	};

	return (
		<Document
			title="FAQs - Frequently Asked Questions"
			ogData={{
				title: "Stay Nimble. Career Coaching for everyone. - Stay Nimble",
				description:
					"Find answers to our commonly asked questions. We want to make sure you feel comfortable when picking Stay Nimble to help you tackle your organisational challenges",
				image: heroVideoposter,
				url: "/faqs/",
			}}
		>
			<FullWidthSection bgColor="" className="pt-md-15 pb-0 pt-10 getting-started-banner">
				<div className="row">
					<div className="col">
						<Title>Frequently Asked Questions</Title>
						<p className="mb-0 mt-4 mx-8 big-text text-center mx-sm-auto" style={{ maxWidth: 555 }}>
							Find answers to our commonly asked questions.
						</p>
					</div>
				</div>
			</FullWidthSection>
			<FAQs faqs={faqData} heading={null} eventCallback={handleEvent} />
		</Document>
	);
};

export default FAQsDocument;
