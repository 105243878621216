import { useCallback, useEffect, useRef, useState } from "react";
import minusIcon from "../../images/programmes/minus-red.svg";
import plusIcon from "../../images/programmes/plus-green.svg";
import Image from "../Image";
import cc from "classcat";
import useProgrammeEvent from "../../hooks/useProgrammeEvent";
import { isFunction, noop } from "lodash";

const FAQItem = ({ faq, className = "", eventCallback = noop, ...props }) => {
	const event = useProgrammeEvent();

	const [titleHighlight, setTitleHighlight] = useState(false);
	const [open, setOpen] = useState(false);
	const [height, setHeight] = useState(0);

	const answerContainerRef = useRef(null);
	const answerRef = useRef(null);
	const timeoutRef = useRef(null);
	const intervalRef = useRef(null);

	const onResize = useCallback(() => {
		const clientHeight = answerRef.current.clientHeight;
		const style = window.getComputedStyle(answerRef.current);
		const marginHeight = parseInt(style.marginTop);
		const totalHeight = clientHeight + marginHeight;
		setHeight(totalHeight);
	}, []);

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("resize", onResize);
			intervalRef.current = setInterval(() => {
				onResize();
			}, 500);

			return () => {
				window.removeEventListener("resize", onResize);
				clearTimeout(intervalRef.current);
			};
		}
	}, [onResize]);

	return (
		<li className={cc(["shadow-block pt-5 px-4 pb-4 rounded-lg faq-item mb-4", className])} {...props}>
			<div
				className="title d-flex align-items-center cursor-pointer"
				onClick={() => {
					setOpen((open) => {
						setTitleHighlight(!open);
						if (timeoutRef.current) {
							clearTimeout(timeoutRef.current);
						}
						if (!open) {
							answerContainerRef.current.style.height = `${height}px`;
							timeoutRef.current = setTimeout(() => {
								setOpen(true);
								if (isFunction(eventCallback)) {
									eventCallback("expand");
								} else {
									event("Expand FAQ", { faq_question: faq.question });
								}
							}, 300);
						} else {
							if (isFunction(eventCallback)) {
								eventCallback("collapse");
							} else {
								event("Collapse FAQ", { faq_question: faq.question });
							}
							setOpen(false);
							timeoutRef.current = setTimeout(() => {
								answerContainerRef.current.style.height = "0px";
							}, 10);
						}
					});
				}}
			>
				<span
					className={cc([
						"font-weight-medium faq-question flex-grow-1",
						{
							"": !titleHighlight,
							"font-weight-bold": titleHighlight,
						},
					])}
				>
					{faq.question}
				</span>
				<span>
					{!titleHighlight ? (
						<Image src={plusIcon} width={25} height={25} />
					) : (
						<Image src={minusIcon} width={25} height={25} alt="-" />
					)}
				</span>
			</div>
			<div className={cc(["answer text-gray-dark", { open: open }])} ref={answerContainerRef}>
				<div className="mt-2 mt-md-4" ref={answerRef} dangerouslySetInnerHTML={{ __html: faq.answer }} />
			</div>
		</li>
	);
};
export default FAQItem;
