import { waitForDOMElReady } from "../../../src/_helpers/_dom";
import useAnonymousDataLayer from "../../../src/_hooks/useAnonymousDataLayer";

/**
 * @return {(function(action: string, extraPayload: object?): void)}
 */
const useProgrammeEvent = () => {
  const { event } = useAnonymousDataLayer();

  const findProgrammeId = async () => {
    try {
      const mainDOM = await waitForDOMElReady("main[data-programme]");
      if (mainDOM) {
        return mainDOM.getAttribute("data-programme");
      }
    } catch {
      return null;
    }
  };

  /**
   *
   * @param {string} action
   * @param {object?} extraPayload
   * @return void
   */
  return async (action, extraPayload = {}) => {
    const programmeId = await findProgrammeId();

    event(
      action,
      "Programme",
      "Programme Interactions",
      programmeId
        ? Object.assign({ programme_id: programmeId }, extraPayload)
        : extraPayload
    );
  };
};

export default useProgrammeEvent;
