export default [
	{
		question: "What is Stay Nimble?",
		answer:
			"Stay Nimble is a career development platform that offers plug-in digital career coaching for teams, focusing on personal and professional growth.",
	},
	{
		question: "How does Stay Nimble's coaching service work?",
		answer:
			"It provides a modular, digital career coaching solution that's quick to launch and flexible for teams of different scales, focusing on personalised and tailored development.",
	},
	{
		question: "What benefits does Stay Nimble offer to organisations?",
		answer:
			"Organisations can see a return on spend, lower turnover rates in high turnover environments, and more income per employee through formalised coaching programs.",
	},
	{
		question: "Can Stay Nimble's coaching be customised to fit our business goals?",
		answer:
			"Yes, the program can be tailored to align with your business needs, offering custom development journeys for team members.",
	},
	{
		question: "How do we track our team's progress with Stay Nimble?",
		answer:
			"Stay Nimble provides live data tracking to monitor team progress and development through their coaching platform.",
	},
	{
		question: "Is there any commitment required to start with Stay Nimble?",
		answer:
			"Stay Nimble offers a free consultation to discuss how their coaching services can plug into your organisation.",
	},
	{
		question: "How does scheduling work for Stay Nimble's coaching sessions?",
		answer:
			"Coaching sessions are scheduled online, allowing for flexibility and convenience without the need for commuting.",
	},
	{
		question: "How does Stay Nimble integrate with existing HR systems?",
		answer:
			"We don’t have any current integrations with HR systems, however we have integration capabilities based on customer requirements.",
	},
	{
		question: "What kind of support does Stay Nimble offer for managers and HR professionals?",
		answer:
			"Stay Nimble offers managers and HR professionals a comprehensive, flexible, and tailored career coaching solution for their teams, with features such as progress tracking, customization, and ROI reporting.",
	},
	{
		question: "How does Stay Nimble measure the impact of its coaching on employee performance?",
		answer:
			"At Stay Nimble, we take a data-driven approach to measure coaching's impact on employee performance. Before coaching, we establish performance baselines aligned with your goals. Throughout the program, we continuously collect and analyse data, providing insights through our award-winning platform. This rigorous process allows us to demonstrate coaching's quantifiable impact on key metrics and return on investment through customised reports.",
	},
	{
		question: "Are there industry-specific coaching programs available?",
		answer:
			"We are able to create and deliver coaching programs designed around customer needs. Previous tailored programs include topics ranging from Digital Marketing to Green Economy, Salesforce and Cloud.",
	},
	{
		question: "How does Stay Nimble ensure confidentiality and data protection?",
		answer:
			"At Stay Nimble, we place the highest priority on ensuring confidentiality and protecting the data of our clients and their employees. All coaching sessions are conducted under a strict policy of 100% confidentiality, with robust procedures in place to safeguard the sensitive information shared. We adhere to stringent data privacy and security standards, complying with regulations such as GDPR, to maintain the integrity and safety of personal employee data. Our secure online platform eliminates the need for physical commuting, minimising potential data exposure risks. We employ advanced technical safeguards, including strict access controls and encryption, to protect data transmitted through our award-winning platform. Furthermore, our registered coaches are bound by professional codes of ethics, reinforcing their unwavering commitment to maintaining confidentiality throughout the coaching process.",
	},
	{
		question: "Can Stay Nimble support organisations with international teams?",
		answer: "Yes, Stay Nimble’s Coaches regularly work with global teams.",
	},
	{
		question: "What are the credentials of Stay Nimble's coaches?",
		answer:
			"All of Stay Nimble’s Coaches are fully qualified Career Coaches, having gained a PostGraduate Diploma in Careers Guidance. All are Registered Career Development Professionals with the Career Development Institute, the UK’s professional body for careers development professionals, and all Coaches are Mental Health First Aid trained. Stay Nimble also provides all Coaches with Coaching Supervision to ensure their own wellbeing and professional development needs are being met.",
	},
	{
		question: "How often are coaching content and resources updated?",
		answer:
			"Stay Nimble’s coaching content and resources are frequently updated, and are specifically tailored to respond to sector changes and needs.",
	},
	{
		question: "Is there a community aspect to Stay Nimble's service for peer support?",
		answer:
			"Stay Nimble has various career development programmes which each have peer LinkedIn groups that members may join if they wish. These provide opportunities to ask questions of peers, share news, ideas and research.",
	},
	{
		question: "What makes Stay Nimble different from other career coaching services?",
		answer:
			"Stay Nimble is an award-winning career coaching service that combines professional online tools and resources with professional one-to-one coaching that is tailored to each individual’s needs. This combination provides a unique service that is completely person-centred.",
	},
	{
		question: "How do I start with Stay Nimble?",
		answer:
			"Individuals can sign up for a free Discovery chat, while organisations can contact Stay Nimble directly to discuss tailored solutions.",
	},
	{
		question: "What results can I expect from Stay Nimble's coaching?",
		answer:
			"Participants can expect enhanced career clarity, development of new skills, and actionable strategies for career progression.",
	},
	{
		question: "How long has Stay Nimble been operating?",
		answer: "Stay Nimble has been empowering career development since 2018.",
	},
	{
		question: "Is Stay Nimble's coaching available worldwide?",
		answer: "Yes, Stay Nimble offers online coaching services, accessible globally.",
	},
	{
		question: "What kind of organisations can benefit from Stay Nimble?",
		answer:
			"Stay Nimble caters to a wide range of organisations, from startups to large corporations, focusing on team and leadership development.",
	},
	{
		question: "How personalised is Stay Nimble's coaching?",
		answer:
			"Stay Nimble tailors its coaching programs to the unique needs and goals of each individual or organisation.",
	},
	{
		question: "Does Stay Nimble offer support for leadership development?",
		answer: "Yes, Stay Nimble provides coaching focused on developing leadership skills and team management.",
	},
	{
		question: "How can Stay Nimble's services impact an organisation's culture?",
		answer:
			"Stay Nimble's coaching can foster a culture of continuous learning, resilience, and adaptability within organisations.",
	},
];
