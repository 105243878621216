import Section from "../section/Section";
import Title from "../Title";
import FAQItem from "./FAQItem";
import cc from "classcat";
import { useEffect } from "react";
import { noop } from "lodash";

const FAQs = ({ faqs = [], className = "", heading = "FAQ", eventCallback = noop, ...props }) => {
	useEffect(() => {
		if (typeof window !== "undefined") {
			if (window.location.hash === "#faq") {
				setTimeout(() => {
					const faq = document.querySelector(".faqs-section");
					faq.scrollIntoView({ behavior: "smooth" });
				}, 100);
			}
		}
	}, []);

	return (
		<Section {...props} className={cc(["faqs-section py-6 py-md-20", className])}>
			<div className="row">
				<div className="col">
					<Title>{heading}</Title>
				</div>
			</div>
			<div className="row">
				<p>
					Stay Nimble offers a transformative career coaching service for organisations and individuals, aiming to fuel
					personal and professional growth within teams. Here are some frequently asked questions to help understand our
					services better:
				</p>
			</div>
			<div className="row mt-6 mt-md-10">
				<div className="col">
					<ul className="list-unstyled m-0 p-0 d-block faq-list">
						{faqs.map((faq, index) => (
							<FAQItem faq={faq} key={index} eventCallback={eventCallback} />
						))}
					</ul>
				</div>
			</div>
		</Section>
	);
};

export default FAQs;
